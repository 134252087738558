import checkAuthenticated from "utils/checkAuthenticated";
import client from "utils/client";
import {
	createContext,
	Dispatch,
	FC,
	SetStateAction,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import { hotjar } from "react-hotjar";
import {
	ActionsRequired,
	LeftoverProperty,
	PropertyDetails,
	UserData,
} from "utils/types";
import Router, { useRouter } from "next/router";
import { useEventTracker_DEPRECATED } from "utils/useEventTracker";
import mixpanel from "mixpanel-browser";
import { useAppContext } from "context/AppContext";
import constants from "utils/constants";
import axios from "axios";
import {
	allPropertiesAreNonGeo,
	multiDashBoardEnabled,
	sortProperties,
} from "utils/account";
import { sendErrorToWebhook } from "utils/sendWebhook";
import moment from "moment";
import { isStagingEnv } from "utils/isStagingEnv";
import { getSessionId } from "utils/getSessionId";
import { usePropertyContext } from "components/pages/Account/Property/context";

export interface IAuthContext {
	isAuthenticated: boolean | null;
	setIsAuthenticated: Dispatch<SetStateAction<boolean | null>>;
	isAuthenticatedToStaging: boolean | null;
	setIsAuthenticatedToStaging: Dispatch<SetStateAction<boolean | null>>;
	properties: PropertyDetails[];
	setProperties: Dispatch<SetStateAction<PropertyDetails[]>>;
	userData: UserData | undefined;
	setUserData: Dispatch<SetStateAction<UserData | undefined>>;
	loading: boolean;
	setLoading: Dispatch<SetStateAction<boolean>>;
	showPremiumPricingModal: boolean;
	setShowPremiumPricingModal: (show: boolean) => void;
	showPremiumInfo: boolean;
	setShowPremiumInfo: (show: boolean) => void;
	shouldShowPremiumButtonDependingOnState: boolean;
	showAccountReferralModal: boolean;
	setShowAccountReferralModal: Dispatch<SetStateAction<boolean>>;
	leftoverProperties: LeftoverProperty[];
	setLeftoverProperties: Dispatch<SetStateAction<LeftoverProperty[]>>;
	showLeftoversModal: boolean;
	setShowLeftoversModal: Dispatch<SetStateAction<boolean>>;
	actionsRequiredCountsLoading: boolean;
	actionsRequiredCounts: ActionsRequired.GetActionsRequiredCounts.Response | null;
	showMobilePropertyNav: boolean;
	setShowMobilePropertyNav: Dispatch<SetStateAction<boolean>>;
}

export const AuthContext = createContext<IAuthContext>({
	isAuthenticated: false,
	setIsAuthenticated: () => {},
	isAuthenticatedToStaging: null,
	setIsAuthenticatedToStaging: () => {},
	properties: [],
	setProperties: () => {},
	userData: undefined,
	setUserData: () => {},
	loading: false,
	setLoading: () => {},
	showPremiumPricingModal: false,
	setShowPremiumPricingModal: () => {},
	showPremiumInfo: false,
	setShowPremiumInfo: () => {},
	shouldShowPremiumButtonDependingOnState: false,
	showAccountReferralModal: false,
	setShowAccountReferralModal: () => {},
	leftoverProperties: [],
	setLeftoverProperties: () => {},
	showLeftoversModal: false,
	setShowLeftoversModal: () => {},
	actionsRequiredCountsLoading: false,
	actionsRequiredCounts: null,
	showMobilePropertyNav: false,
	setShowMobilePropertyNav: () => {},
});

const END_REFERRAL_REMINDER_DATE = "2024-05-15";

export const AuthContextProivder: FC = ({ children }) => {
	const { modalToShow, details } = usePropertyContext();
	const { mixpanelInitialized, hotjarInitialized } = useAppContext();
	const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
	const [didCheckAuthenticated, setDidCheckedAuthenticated] = useState(false);
	const [userData, setUserData] = useState<UserData>();
	const [properties, setProperties] = useState<PropertyDetails[]>([]);
	const [isInPortal, setIsInPortal] = useState<boolean>();
	const [trackedPortalVisit, setTrackedPortalVisit] = useState(false);
	const [loading, setLoading] = useState(false);
	const [showAccountReferralModal, setShowAccountReferralModal] =
		useState(false);
	const [leftoverProperties, setLeftoverProperties] = useState<
		LeftoverProperty[]
	>([]);
	const [showLeftoversModal, setShowLeftoversModal] = useState(false);
	const [actionsRequiredCountsLoading, setActionsRequiredCountsLoading] =
		useState(false);
	const [actionsRequiredCounts, setActionsRequiredCounts] =
		useState<ActionsRequired.GetActionsRequiredCounts.Response | null>(null);

	const [isAuthenticatedToStaging, setIsAuthenticatedToStaging] = useState<
		boolean | null
	>(null);
	const [showMobilePropertyNav, setShowMobilePropertyNav] = useState(false);

	const trackPortalVisit = useEventTracker_DEPRECATED(
		constants.EVENTS.PORTAL_VISIT,
		{
			customer_id: userData?.id,
			email: userData?.email,
		}
	);

	const shouldShowPremiumButtonDependingOnState = useMemo(() => {
		if (!properties.length) return false;
		return !!properties.find(property =>
			constants.STATES_SERVED.includes(
				property.state as typeof constants.STATES_SERVED[number]
			)
		);
	}, [properties]);

	const router = useRouter();

	useEffect(() => {
		if (!isStagingEnv()) return;
		const token = window.localStorage.getItem("staging_auth_token");

		if (!token) {
			setIsAuthenticatedToStaging(false);
			Router.push("/");
			return;
		}
		setIsAuthenticatedToStaging(true);
		axios.defaults.headers.common["Staging-Authorization"] = `Bearer ${token}`;
	}, []);

	useEffect(() => {
		if (
			didCheckAuthenticated ||
			typeof window === "undefined" ||
			!router.isReady
		) {
			return;
		}

		const authed = checkAuthenticated(router);

		if (authed !== undefined) {
			setIsAuthenticated(authed);
		}

		setDidCheckedAuthenticated(true);
	}, [router, didCheckAuthenticated]);

	useEffect(() => {
		const interceptorId = axios.interceptors.response.use(
			res => res,
			async error => {
				if (axios.isAxiosError(error) && error.response?.status === 421) {
					setIsAuthenticated(false);

					if (isStagingEnv()) {
						setIsAuthenticatedToStaging(false);
						window.localStorage.removeItem("staging_auth_token");
					}
				} else if (
					axios.isAxiosError(error) &&
					error.response?.status === 401 &&
					isAuthenticated &&
					!isInPortal
				) {
					Router.push("/account/properties");
				}
				return Promise.reject(error);
			}
		);

		return () => {
			axios.interceptors.response.eject(interceptorId);
		};
	}, []);

	// Performs initial authenticated data fetching
	useEffect(() => {
		if (!didCheckAuthenticated) return;
		if (isAuthenticated) {
			setLoading(true);
			client
				.getUserData()
				.then(data => {
					setUserData(data);
				})
				.then(client.getProperties)
				.then(res => {
					const sorted = res.data.sort(sortProperties);
					setProperties(sorted);
				})
				.catch(() => {
					setIsAuthenticated(false);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	}, [isAuthenticated, didCheckAuthenticated]);

	useEffect(() => {
		if (multiDashBoardEnabled(userData) && isAuthenticated) {
			setActionsRequiredCountsLoading(true);
			client
				.getActionsRequiredCounts()
				.then(res => {
					setActionsRequiredCounts(res.data);
				})
				.catch(() => {
					setActionsRequiredCounts(null);
				})
				.finally(() => {
					setActionsRequiredCountsLoading(false);
				});
		}
	}, [userData, isAuthenticated]);

	useEffect(() => {
		const checkPropertyModalEl = () => {
			return !!document.querySelector(".account-property-modal-card");
		};
		try {
			if (!properties.length || !!router.query.onboarding) return;

			const LEFTOVERS_LOCAL_STORAGE_KEY = "shown_leftovers";

			let shownLeftoversModal = window.localStorage.getItem(
				LEFTOVERS_LOCAL_STORAGE_KEY
			);

			const REFERRAL_LOCAL_STORAGE_KEY = "shown_referral_modal";

			const shownReferralModal = window.localStorage.getItem(
				REFERRAL_LOCAL_STORAGE_KEY
			);

			// If we have not shown the leftovers modal in over a month, show it again.
			if (
				shownLeftoversModal &&
				moment().isAfter(moment(shownLeftoversModal).add(1, "month"))
			) {
				window.localStorage.setItem(
					LEFTOVERS_LOCAL_STORAGE_KEY,
					moment.utc().toString()
				);
				shownLeftoversModal = null;
			}

			if (!shownLeftoversModal) {
				const fetchLeftovers = async () => {
					try {
						const { data } = await client.getLeftovers();

						setLeftoverProperties(data);

						if (checkPropertyModalEl()) return;
						setShowLeftoversModal(data.length > 0);
					} catch (e) {
						console.error(e);
					}
				};
				window.localStorage.setItem(
					LEFTOVERS_LOCAL_STORAGE_KEY,
					moment.utc().toString()
				);

				fetchLeftovers();
				return;
			} else {
				const url = new URL(window.location.href);
				const hasQueryParams = Array.from(url.searchParams).length > 0;
				const isPropertiesPage = url.pathname.endsWith("/account/properties");
				const isPropertyPage = /\/account\/properties\/\d/.test(url.pathname);
				const shouldShowReferralModal =
					!hasQueryParams && (isPropertyPage || isPropertiesPage);
				if (
					!shouldShowReferralModal ||
					properties?.length > 21 ||
					moment().isAfter(END_REFERRAL_REMINDER_DATE) ||
					!!modalToShow ||
					allPropertiesAreNonGeo(properties)
				)
					return;
				if (shownReferralModal) {
					window.localStorage.setItem(
						"shown_referral_modal",
						String(Number(shownReferralModal) + 1)
					);
					if (properties?.length > 5) {
						if ((Number(shownReferralModal) + 1) % 8 === 0) {
							setTimeout(() => {
								if (checkPropertyModalEl()) return;
								setShowAccountReferralModal(true);
							}, 2500);
						}
					} else {
						if ((Number(shownReferralModal) + 1) % 5 === 0) {
							setTimeout(() => {
								if (checkPropertyModalEl()) return;
								setShowAccountReferralModal(true);
							}, 2500);
						}
					}
				} else {
					setTimeout(() => {
						if (checkPropertyModalEl()) return;
						setShowAccountReferralModal(true);
					}, 2500);
					window.localStorage.setItem("shown_referral_modal", "1");
				}
			}
		} catch (err) {
			console.error(err);
		}
	}, [properties.length, router.query.onboarding]);

	useEffect(() => {
		if (isInPortal && !trackedPortalVisit) {
			trackPortalVisit();
			setTrackedPortalVisit(true);
		}
	}, [isInPortal, trackedPortalVisit, trackPortalVisit]);

	useEffect(() => {
		if (hotjarInitialized && userData) {
			const { id, created_at, num_active_properties, account_tier } = userData;
			try {
				hotjar.identify(String(id), {
					created_at,
					num_active_properties,
					account_tier,
				});
			} catch {
				sendErrorToWebhook(
					`Invalid customer id ${id} for customer ${userData.id}`
				);
			}
		}
	}, [hotjarInitialized, userData]);

	useEffect(() => {
		if (mixpanelInitialized && userData) {
			const { id, created_at } = userData;
			try {
				mixpanel.identify(String(id));
				mixpanel.register({
					created_at: moment.utc(created_at).toISOString(),
				});
			} catch {
				sendErrorToWebhook(
					`Invalid customer created_at ${created_at} for customer ${userData.id}`
				);
			}
		}
	}, [mixpanelInitialized, userData]);

	const routeChangeHandler = useCallback(
		(pathname: string) => {
			if (isAuthenticated) {
				const inPortal = pathname.startsWith("/account");

				setIsInPortal(inPortal);

				if (!inPortal) {
					setTrackedPortalVisit(false);
				}
			} else {
				setIsInPortal(false);
			}
		},
		[isAuthenticated]
	);

	useEffect(() => {
		router.events.on("routeChangeComplete", routeChangeHandler);

		return () => {
			router.events.off("routeChangeComplete", routeChangeHandler);
		};
	}, [routeChangeHandler]);

	useEffect(() => {
		try {
			const sessionId = getSessionId();

			const urlParams = new URLSearchParams(window.location.search);

			const utm_source = urlParams.get("utm_source");
			const utm_medium = urlParams.get("utm_medium");
			const utm_campaign = urlParams.get("utm_campaign");
			const utm_term = urlParams.get("utm_term");
			const utm_content = urlParams.get("utm_content");

			const paramsObject = Object.fromEntries(urlParams.entries());

			const abortController = new AbortController();

			client
				.trackVisit({
					sessionId,
					utm_source,
					utm_medium,
					utm_campaign,
					utm_term,
					utm_content,
					abortController,
					customer_id: userData?.id ?? null,
					deviceId: null,
				})
				.then(() => {
					return client.trackAction({
						sessionId,
						url: window.location.pathname,
						params: paramsObject,
						abortController,
					});
				})
				.catch(err => {
					console.error(err);
				});

			return () => abortController.abort();
		} catch {}
	}, [userData?.id]);

	useEffect(() => {
		try {
			const abortController = new AbortController();

			const handler = () => {
				const sessionId = getSessionId();
				const urlParams = new URLSearchParams(window.location.search);

				const paramsObject = Object.fromEntries(urlParams.entries());

				client.trackAction({
					sessionId,
					url: window.location.pathname,
					params: paramsObject,
					abortController,
				});
			};

			Router.events.on("routeChangeComplete", handler);

			return () => {
				Router.events.off("routeChangeComplete", handler);
				abortController.abort();
			};
		} catch {}
	}, []);

	const showPremiumPricingModal = useMemo(() => {
		return router.query["premium-pricing"] === "true";
	}, [router.query]);

	const setShowPremiumPricingModal = (show: boolean) => {
		let query = Router.query;
		if (show) {
			query["premium-pricing"] = "true";
		} else {
			const query = Router.query;
			delete query["premium-pricing"];
		}
		Router.push(
			{
				pathname: Router.pathname,
				query,
			},
			undefined,
			{ shallow: true }
		);
	};

	const showPremiumInfo = useMemo(() => {
		return router.query["premium-info"] === "true";
	}, [router.query]);

	const setShowPremiumInfo = (show: boolean) => {
		let query = Router.query;
		if (show) {
			query["premium-info"] = "true";
		} else {
			const query = Router.query;
			delete query["premium-info"];
		}
		Router.push(
			{
				pathname: Router.pathname,
				query,
			},
			undefined,
			{ shallow: true }
		);
	};

	return (
		<AuthContext.Provider
			value={{
				setProperties,
				properties,
				isAuthenticated,
				setIsAuthenticated,
				isAuthenticatedToStaging,
				setIsAuthenticatedToStaging,
				userData,
				setUserData,
				loading,
				setLoading,
				showPremiumInfo,
				setShowPremiumInfo,
				showPremiumPricingModal,
				setShowPremiumPricingModal,
				shouldShowPremiumButtonDependingOnState,
				showAccountReferralModal,
				setShowAccountReferralModal,
				leftoverProperties,
				setLeftoverProperties,
				showLeftoversModal,
				setShowLeftoversModal,
				actionsRequiredCountsLoading,
				actionsRequiredCounts,
				showMobilePropertyNav,
				setShowMobilePropertyNav,
			}}>
			{children}
		</AuthContext.Provider>
	);
};

export const useAuthContext = () => useContext(AuthContext);
