import { AbTest } from "elements/AbTest";
import Router, { useRouter } from "next/router";
import { set } from "nprogress";
import {
	Dispatch,
	SetStateAction,
	createContext,
	useContext,
	FC,
	useState,
	useCallback,
	useEffect,
	useMemo,
} from "react";
import { sendErrorToWebhook } from "utils/sendWebhook";
import { isGeoLevelSavingsData } from "utils/typePredicates";
import {
	ContactInfo,
	IAddress,
	LegalData,
	SavingsType,
	SavingsData,
	SignUpType,
	ExemptionCurrentlyReceivingValues,
	SignUpFlowServiceType,
} from "utils/types";

import { getSavingsCopyType } from "utils/getSavingsCopy";

export interface LegalResponse {
	email_sha256: string;
	phone_sha256: string;
	referral: string;
	confirm_otc: boolean;
	property_id: number;
	appeal_id: number;
	customer_id: number;
	created_new_customer: boolean;
	exemption_stage: string | undefined;
	exemption_id: number | undefined;
}

export interface IAppealContext {
	prevPathName: string;
	session: string | undefined;
	userReferralCode: string | undefined;
	setUserReferralCode: Dispatch<SetStateAction<string | undefined>>;
	setSession: Dispatch<SetStateAction<string | undefined>>;
	savingsData: SavingsData | undefined;
	setSavingsData: Dispatch<SetStateAction<SavingsData | undefined>>;
	address: IAddress | undefined;
	setAddress: Dispatch<SetStateAction<IAddress | undefined>>;
	contactInfo: ContactInfo | undefined;
	setContactInfo: Dispatch<SetStateAction<ContactInfo | undefined>>;
	legalData: LegalData | undefined;
	setLegalData: Dispatch<SetStateAction<LegalData | undefined>>;
	isMailer: boolean;
	setIsMailer: Dispatch<SetStateAction<boolean>>;
	startAppealFlow: (path?: string) => void;
	resetAppealFlow: () => void;
	shownSavingsAnimation: boolean;
	setShownSavingsAnimation: Dispatch<SetStateAction<boolean>>;
	isTakeover: boolean | undefined;
	setIsTakeover: Dispatch<SetStateAction<boolean | undefined>>;
	addressRaw: string | undefined;
	setAddressRaw: Dispatch<SetStateAction<string | undefined>>;
	apnRaw: string | undefined;
	setApnRaw: Dispatch<SetStateAction<string | undefined>>;
	hydrating: boolean;
	tryingToClose: boolean;
	setTryingToClose: Dispatch<SetStateAction<boolean>>;
	ownedByCompany: boolean | undefined;
	setOwnedByCompany: Dispatch<SetStateAction<boolean | undefined>>;
	smsConsent: boolean;
	setSmsConsent: Dispatch<SetStateAction<boolean>>;
	nonAutocompleteParcelNumber: boolean;
	setNonAutocompleteParcelNumber: Dispatch<SetStateAction<boolean>>;
	savingsType: SavingsType | undefined;
	setSavingsType: Dispatch<SetStateAction<SavingsType | undefined>>;
	backBtnFnOverride: (() => void) | null;
	setBackBtnFnOverride: Dispatch<SetStateAction<(() => void) | null>>;
	activeFreeze: boolean | undefined;
	setActiveFreeze: Dispatch<SetStateAction<boolean | undefined>>;
	recentPurchase: boolean | undefined;
	setRecentPurchase: Dispatch<SetStateAction<boolean | undefined>>;
	mustConfirmEmail: boolean | undefined;
	setMustConfirmEmail: Dispatch<SetStateAction<boolean | undefined>>;
	authorizeAs: string | undefined;
	setAuthorizeAs: Dispatch<SetStateAction<string | undefined>>;
	propertyId: number | undefined;
	setPropertyId: Dispatch<SetStateAction<number | undefined>>;
	customerId: number | undefined;
	setCustomerId: Dispatch<SetStateAction<number | undefined>>;
	appealId: number | undefined;
	setAppealId: Dispatch<SetStateAction<number | undefined>>;
	exemptionId: number | undefined;
	setExemptionId: Dispatch<SetStateAction<number | undefined>>;
	propertyAlreadySignedUp: boolean | undefined;
	setPropertyAlreadySignedUp: Dispatch<SetStateAction<boolean | undefined>>;
	currentlyReceivingValue: ExemptionCurrentlyReceivingValues | undefined;
	setCurrentlyReceivingValue: Dispatch<
		SetStateAction<ExemptionCurrentlyReceivingValues | undefined>
	>;
	moveInDateValue: string | undefined;
	setMoveInDateValue: Dispatch<SetStateAction<string | undefined>>;
	primaryResidenceValue: boolean | undefined;
	setPrimaryResidenceValue: Dispatch<SetStateAction<boolean | undefined>>;
	purchaseDateValue: string | undefined;
	setPurchaseDateValue: Dispatch<SetStateAction<string | undefined>>;
	contactEmail: string;
	setContactEmail: Dispatch<SetStateAction<string>>;
	isEndToEndExemptionsFlow: boolean;
	setIsExemptionsEndToEndFlow: Dispatch<SetStateAction<boolean>>;
	createdExemptionStage: "Archive" | "Started" | undefined;
	setCreatedExemptionStage: Dispatch<
		SetStateAction<"Archive" | "Started" | undefined>
	>;
	signUpType: SignUpType;
	setSignUpType: Dispatch<SetStateAction<SignUpType>>;
	legalResponse: LegalResponse | undefined;
	setLegalResponse: Dispatch<SetStateAction<LegalResponse | undefined>>;
	hideSavingsUI: boolean;
	signUpServiceType: SignUpFlowServiceType | undefined;
	setSignUpServiceType: Dispatch<SetStateAction<SignUpFlowServiceType>>;
	clickedAiVideo: boolean;
	setClickedAiVideo: Dispatch<SetStateAction<boolean>>;
	alreadySavingOnExemption: boolean;
	appealFlowRedirect: string | undefined;
	setAppealFlowRedirect: Dispatch<SetStateAction<string | undefined>>;
}

export const AppealContext = createContext<IAppealContext>({
	prevPathName: "",
	session: "",
	userReferralCode: undefined,
	setUserReferralCode: () => {},
	setSession: () => {},
	savingsData: undefined,
	setSavingsData: () => {},
	address: undefined,
	setAddress: () => {},
	isTakeover: false,
	setIsTakeover: () => {},
	contactInfo: undefined,
	setContactInfo: () => {},
	legalData: undefined,
	setLegalData: () => {},
	isMailer: false,
	setIsMailer: () => {},
	startAppealFlow: () => {},
	resetAppealFlow: () => {},
	setShownSavingsAnimation: () => {},
	addressRaw: undefined,
	setAddressRaw: () => {},
	apnRaw: undefined,
	setApnRaw: () => {},
	shownSavingsAnimation: false,
	hydrating: true,
	tryingToClose: false,
	setTryingToClose: () => {},
	ownedByCompany: undefined,
	setOwnedByCompany: () => {},
	smsConsent: false,
	setSmsConsent: () => {},
	savingsType: "exact",
	setSavingsType: () => {},
	nonAutocompleteParcelNumber: false,
	setNonAutocompleteParcelNumber: () => {},
	backBtnFnOverride: null,
	setBackBtnFnOverride: () => {},
	activeFreeze: undefined,
	setActiveFreeze: () => {},
	recentPurchase: undefined,
	setRecentPurchase: () => {},
	mustConfirmEmail: undefined,
	setMustConfirmEmail: () => {},
	authorizeAs: "",
	setAuthorizeAs: () => {},
	propertyId: undefined,
	setPropertyId: () => {},
	customerId: undefined,
	setCustomerId: () => {},
	appealId: undefined,
	setAppealId: () => {},
	exemptionId: undefined,
	setExemptionId: () => {},
	propertyAlreadySignedUp: undefined,
	setPropertyAlreadySignedUp: () => {},
	currentlyReceivingValue: undefined,
	setCurrentlyReceivingValue: () => {},
	moveInDateValue: undefined,
	setMoveInDateValue: () => {},
	primaryResidenceValue: undefined,
	setPrimaryResidenceValue: () => {},
	purchaseDateValue: undefined,
	setPurchaseDateValue: () => {},
	contactEmail: "",
	setContactEmail: () => {},
	isEndToEndExemptionsFlow: false,
	setIsExemptionsEndToEndFlow: () => {},
	createdExemptionStage: undefined,
	setCreatedExemptionStage: () => {},
	signUpType: "appeal_only",
	setSignUpType: () => {},
	legalResponse: undefined,
	setLegalResponse: () => {},
	hideSavingsUI: false,
	signUpServiceType: "default",
	setSignUpServiceType: () => {},
	clickedAiVideo: false,
	setClickedAiVideo: () => {},
	alreadySavingOnExemption: false,
	appealFlowRedirect: undefined,
	setAppealFlowRedirect: () => {},
});

export const AppealContextProvider: FC = ({ children }) => {
	// Public state
	const [prevPathName, setPrevPathName] = useState("");
	const [savingsData, setSavingsData] = useState<SavingsData>();
	const [contactInfo, setContactInfo] = useState<ContactInfo>();
	const [legalData, setLegalData] = useState<LegalData>();
	const [userReferralCode, setUserReferralCode] = useState<string>();
	const [isMailer, setIsMailer] = useState(false);
	const [session, setSession] = useState<string>();
	const [address, setAddress] = useState<IAddress>();
	const [shownSavingsAnimation, setShownSavingsAnimation] = useState(false);
	const [hydrating, setHydrating] = useState(true);
	const [addressRaw, setAddressRaw] = useState<string>();
	const [apnRaw, setApnRaw] = useState<string>();
	const [isTakeover, setIsTakeover] = useState<boolean>();
	const [tryingToClose, setTryingToClose] = useState(false);
	const [ownedByCompany, setOwnedByCompany] = useState<boolean>();
	const [smsConsent, setSmsConsent] = useState(false);
	const [savingsType, setSavingsType] = useState<SavingsType | undefined>(
		"exact"
	);
	const [nonAutocompleteParcelNumber, setNonAutocompleteParcelNumber] =
		useState(false);
	const [backBtnFnOverride, setBackBtnFnOverride] = useState<
		(() => void) | null
	>(null);
	const [activeFreeze, setActiveFreeze] = useState<boolean>();
	const [recentPurchase, setRecentPurchase] = useState<boolean>();
	const [mustConfirmEmail, setMustConfirmEmail] = useState<boolean>();
	const [authorizeAs, setAuthorizeAs] = useState<string>();
	const [propertyId, setPropertyId] = useState<number>();
	const [customerId, setCustomerId] = useState<number>();
	const [appealId, setAppealId] = useState<number>();
	const [exemptionId, setExemptionId] = useState<number>();
	const [propertyAlreadySignedUp, setPropertyAlreadySignedUp] =
		useState<boolean>();
	// Using this state is kind of a hack because we want to display the email on the propertyAlreadySignedUp page but we haven't set contactInfo by the time that question is displayed
	const [contactEmail, setContactEmail] = useState("");
	const [currentlyReceivingValue, setCurrentlyReceivingValue] = useState<
		| "no"
		| "notSure"
		| "yesAnotherProperty"
		| "yes"
		| "yesAnotherPropertyInAnotherState"
		| "yesAnotherPropertyInCalifornia"
	>();
	const [moveInDateValue, setMoveInDateValue] = useState<string>();
	const [purchaseDateValue, setPurchaseDateValue] = useState<string>();
	const [primaryResidenceValue, setPrimaryResidenceValue] = useState<boolean>();
	const [isEndToEndExemptionsFlow, setIsExemptionsEndToEndFlow] =
		useState(false);
	const [createdExemptionStage, setCreatedExemptionStage] = useState<
		"Archive" | "Started" | undefined
	>();
	const [signUpType, setSignUpType] = useState<SignUpType>("appeal_only");
	const [legalResponse, setLegalResponse] = useState<LegalResponse>();
	const [signUpServiceType, setSignUpServiceType] =
		useState<SignUpFlowServiceType>("default");
	const [clickedAiVideo, setClickedAiVideo] = useState<boolean>(false);
	const [appealFlowRedirect, setAppealFlowRedirect] = useState<
		string | undefined
	>();

	const router = useRouter();

	useEffect(() => {
		if (router.query.appeal_flow_redirect) {
			setAppealFlowRedirect(router.query.appeal_flow_redirect as string);
		}
	}, [router.query.appeal_flow_redirect]);

	// Generate session for appeals table tracking. Generate this one time when address gets set and session does not exist.
	useEffect(() => {
		if (!hydrating && address && !session) {
			const sessionId =
				Math.random().toString(36).substring(2) + Date.now().toString(36);
			setSession(sessionId);
		}
	}, [address, hydrating, session]);

	// If user starts on the exemptions page, the user gets the e2e exemptions flow
	useEffect(() => {
		const pathname = router.pathname;
		if (pathname.startsWith("/appeal")) return;
		if (pathname === "/exemptions") {
			setIsExemptionsEndToEndFlow(true);
		} else {
			setIsExemptionsEndToEndFlow(false);
		}
	}, [router.pathname]);

	// If exemptions=true in the query params, the user gets the e2e exemptions flow
	useEffect(() => {
		if (router.pathname !== "/appeal") return; // If we are not on the address page, don't do anything

		const showExmeptions = !!router.query.exemptions;

		setIsExemptionsEndToEndFlow(showExmeptions);
	}, [router.query, router.pathname]);

	useEffect(() => {
		if (!savingsData) return;

		// Funnel CA Prop 13 properties into the E2E exemption flow
		if (
			address?.state === "CA" &&
			!isGeoLevelSavingsData(savingsData) &&
			savingsData.inService &&
			savingsData.exemptionsInService &&
			savingsData.exemption_capped_no_chance_of_savings
		) {
			setIsExemptionsEndToEndFlow(true);
			setSignUpType("appeal_and_exemption");
			return;
		}

		if (
			isEndToEndExemptionsFlow &&
			savingsData.exemptionsInService &&
			savingsData.inService
		) {
			setSignUpType("appeal_and_exemption");
			return;
		}

		if (
			!isGeoLevelSavingsData(savingsData) &&
			savingsData.exemptions_retro_target &&
			savingsData.exemptionsInService &&
			savingsData.inService
		) {
			setSignUpType("appeal_and_exemption");
			return;
		}

		if (!savingsData.inService && savingsData.exemptionsInService) {
			setSignUpType("exemption_only");
			return;
		}

		if (!savingsData.inService && !savingsData.exemptionsInService) {
			setSignUpType("property_only");
			return;
		}
		setSignUpType("appeal_only");
	}, [savingsData, isEndToEndExemptionsFlow, address]);

	const alreadySavingOnExemption = useMemo(() => {
		const savingsCopyType = getSavingsCopyType({
			nonAutocompleteParcelNumber,
			savingsData,
			addressRaw,
		});

		if (!savingsData) return false;

		return (
			(savingsCopyType === "exemption-or-zero" ||
				savingsCopyType === "unknown-assessment" ||
				savingsCopyType === "non-apn") &&
			isEndToEndExemptionsFlow &&
			!isGeoLevelSavingsData(savingsData) &&
			savingsData.exemptionsInService
		);
	}, [
		isEndToEndExemptionsFlow,
		savingsData,
		nonAutocompleteParcelNumber,
		addressRaw,
	]);

	const startAppealFlow = useCallback(
		(path?: string) => {
			let page = "/appeal";

			if (path && typeof path === "string") {
				page = path;
			}

			setPrevPathName(router.pathname);

			Router.push(page, undefined, { shallow: true });
		},
		[router.pathname]
	);

	const resolveSignUpServiceType = useCallback(() => {
		if (Router.query.exemptions) {
			setSignUpServiceType("exemption");
		} else if (Router.pathname === "/appeals") {
			setSignUpServiceType("appeal");
		} else if (Router.pathname === "/exemptions") {
			setSignUpServiceType("exemption");
		} else if (Router.pathname === "/bills") {
			setSignUpServiceType("bill-reduction");
		}
	}, []);

	const resetAppealFlow = useCallback(() => {
		setSession(undefined);
		setApnRaw(undefined);
		setAddress(undefined);
		setAddressRaw(undefined);
		setSavingsData(undefined);
		setLegalData(undefined);
		setContactInfo(undefined);
		setIsTakeover(undefined);
		setOwnedByCompany(undefined);
		setShownSavingsAnimation(false);
		setNonAutocompleteParcelNumber(false);
		setMustConfirmEmail(undefined);
		setRecentPurchase(undefined);
		setAuthorizeAs(undefined);
		setSavingsType("exact");
		setPropertyAlreadySignedUp(undefined);
		setCreatedExemptionStage(undefined);
		setSignUpType("appeal_only");
		setSignUpServiceType("default");
		setClickedAiVideo(false);
		if (Router.pathname !== "/exemptions") {
			setIsExemptionsEndToEndFlow(false);
		}
		try {
			window.sessionStorage.removeItem("appealData");
			window.sessionStorage.removeItem("tracked_confirmation");
			window.localStorage.removeItem("test_show_savings_ui"); // We should reset this AbTest for each adddress
			window.localStorage.removeItem("test_ca_fl_ny_signup_savings_variation");
		} catch (err) {
			console.error(err);
		}
	}, []);

	useEffect(() => {
		if (!router.isReady || hydrating) return;

		if (
			!router.pathname.startsWith("/appeal/") &&
			router.pathname !== "/appeal"
		) {
			resetAppealFlow();
			resolveSignUpServiceType();
		}
	}, [router.isReady, hydrating, router.pathname, resetAppealFlow]);

	const setAddressFromUrlParams = useCallback(() => {
		if (
			router.query.address &&
			router.query.zipcode &&
			router.query.state &&
			router.query.city
		) {
			setAddress({
				address: decodeURIComponent(router.query.address as string).replace(
					/\+/g,
					" "
				),
				line2: "",
				city: decodeURIComponent(router.query.city as string),
				state: decodeURIComponent(router.query.state as string).toUpperCase(),
				zipcode: decodeURIComponent(router.query.zipcode as string),
			});
		}
	}, [
		router.query.address,
		router.query.city,
		router.query.state,
		router.query.zipcode,
	]);

	useEffect(() => {
		if (typeof window === "undefined") {
			setHydrating(false);
			return;
		}

		if (!router.isReady) return;

		try {
			const pathname = window.location.pathname;
			const appealDataStr = window.sessionStorage.getItem("appealData");

			if (!appealDataStr) {
				setHydrating(false);
				setAddressFromUrlParams(); // In order to have URL param support for address, we need to set the address before hydrating is set to false

				return;
			}

			const appealData = JSON.parse(appealDataStr);

			if (!appealData) {
				setHydrating(false);
				setAddressFromUrlParams(); // In order to have URL param support for address, we need to set the address before hydrating is set to false
				return;
			}

			const {
				prevPathName,
				contactInfo,
				userReferralCode,
				apnRaw,
				addressRaw,
				savingsData,
				isMailer,
				legalData,
				session,
				address,
				isTakeover,
				ownedByCompany,
				nonAutocompleteParcelNumber,
				savingsType,
				mustConfirmEmail,
				authorizeAs,
				propertyId,
				isEndToEndExemptionsFlow,
				primaryResidenceValue,
				currentlyReceivingValue,
				moveInDateValue,
				purchaseDateValue,
				createdExemptionStage,
				signUpType,
				signUpServiceType,
				appealId,
				clickedAiVideo,
			} = appealData;

			setPrevPathName(prevPathName);
			setAddressRaw(addressRaw);
			setSavingsData(savingsData);
			setLegalData(legalData);
			setContactInfo(contactInfo);
			setUserReferralCode(userReferralCode);
			setIsMailer(isMailer);
			setSession(session);
			setAddress(address);
			setApnRaw(apnRaw);
			setIsTakeover(isTakeover);
			setOwnedByCompany(ownedByCompany);
			setNonAutocompleteParcelNumber(nonAutocompleteParcelNumber);
			setSavingsType(savingsType);
			setMustConfirmEmail(mustConfirmEmail);
			setAuthorizeAs(authorizeAs);
			setPropertyId(propertyId);
			setPrimaryResidenceValue(primaryResidenceValue);
			setCurrentlyReceivingValue(currentlyReceivingValue);
			setMoveInDateValue(moveInDateValue);
			setPurchaseDateValue(purchaseDateValue);
			setCreatedExemptionStage(createdExemptionStage);
			setSignUpType(signUpType);
			setSignUpServiceType(signUpServiceType);
			setAppealId(appealId);
			setClickedAiVideo(clickedAiVideo);

			if (
				isEndToEndExemptionsFlow !== undefined &&
				pathname !== "/exemptions"
			) {
				setIsExemptionsEndToEndFlow(isEndToEndExemptionsFlow);
			}

			setAddressFromUrlParams(); // In order to have URL param support for address, we need to set the address before hydrating is set to false

			setHydrating(false);
		} catch (err) {
			console.error(err);
		}
	}, [setAddressFromUrlParams, router.isReady]);

	useEffect(() => {
		if (hydrating) return;
		const appealData = {
			prevPathName,
			savingsData,
			contactInfo,
			legalData,
			userReferralCode,
			isMailer,
			session,
			address,
			addressRaw,
			isTakeover,
			ownedByCompany,
			nonAutocompleteParcelNumber,
			savingsType,
			apnRaw,
			mustConfirmEmail,
			authorizeAs,
			propertyId,
			isEndToEndExemptionsFlow,
			primaryResidenceValue,
			currentlyReceivingValue,
			moveInDateValue,
			createdExemptionStage,
			signUpType,
			signUpServiceType,
			appealId,
			clickedAiVideo,
		};

		try {
			window.sessionStorage.setItem("appealData", JSON.stringify(appealData));
		} catch (err) {
			console.error(err);
		}
	}, [
		hydrating,
		prevPathName,
		savingsData,
		addressRaw,
		legalData,
		contactInfo,
		userReferralCode,
		isMailer,
		session,
		apnRaw,
		address,
		isTakeover,
		ownedByCompany,
		savingsType,
		nonAutocompleteParcelNumber,
		mustConfirmEmail,
		authorizeAs,
		propertyId,
		isEndToEndExemptionsFlow,
		createdExemptionStage,
		signUpType,
		primaryResidenceValue,
		currentlyReceivingValue,
		moveInDateValue,
		signUpServiceType,
		clickedAiVideo,
	]);

	const hideSavingsUI = useMemo(() => {
		if (!savingsData) return false;
		if (isGeoLevelSavingsData(savingsData)) return false;

		return !savingsData.show_savings_on_website;
	}, [savingsData]);

	return (
		<AppealContext.Provider
			value={{
				prevPathName,
				savingsData,
				setSavingsData,
				legalData,
				setLegalData,
				session,
				setSession,
				address,
				isMailer,
				setIsMailer,
				setAddress,
				contactInfo,
				setContactInfo,
				userReferralCode,
				setUserReferralCode,
				startAppealFlow,
				resetAppealFlow,
				shownSavingsAnimation,
				setShownSavingsAnimation,
				isTakeover,
				setIsTakeover,
				ownedByCompany,
				setOwnedByCompany,
				hydrating,
				addressRaw,
				setAddressRaw,
				tryingToClose,
				setTryingToClose,
				smsConsent,
				setSmsConsent,
				savingsType,
				setSavingsType,
				setApnRaw,
				apnRaw,
				nonAutocompleteParcelNumber,
				setNonAutocompleteParcelNumber,
				backBtnFnOverride,
				setBackBtnFnOverride,
				activeFreeze,
				setActiveFreeze,
				recentPurchase,
				setRecentPurchase,
				mustConfirmEmail,
				setMustConfirmEmail,
				authorizeAs,
				setAuthorizeAs,
				propertyId,
				setPropertyId,
				customerId,
				setCustomerId,
				appealId,
				setAppealId,
				exemptionId,
				setExemptionId,
				propertyAlreadySignedUp,
				setPropertyAlreadySignedUp,
				contactEmail,
				setContactEmail,
				primaryResidenceValue,
				setPrimaryResidenceValue,
				currentlyReceivingValue,
				setCurrentlyReceivingValue,
				moveInDateValue,
				setMoveInDateValue,
				purchaseDateValue,
				setPurchaseDateValue,
				isEndToEndExemptionsFlow,
				setIsExemptionsEndToEndFlow,
				createdExemptionStage,
				setCreatedExemptionStage,
				signUpType,
				setSignUpType,
				legalResponse,
				setLegalResponse,
				hideSavingsUI,
				signUpServiceType,
				setSignUpServiceType,
				clickedAiVideo,
				setClickedAiVideo,
				alreadySavingOnExemption,
				appealFlowRedirect,
				setAppealFlowRedirect,
			}}>
			{children}
			<AbTestCAFLSignUpSavingsUI.Initialize />
		</AppealContext.Provider>
	);
};

export const useAppealContext = () => useContext(AppealContext);

namespace AbTestCAFLSignUpSavingsUI {
	const OverrideSignUpServiceType = ({
		signUpServiceTypeOverride,
	}: {
		signUpServiceTypeOverride: SignUpFlowServiceType;
	}) => {
		const { setSignUpServiceType } = useAppealContext();
		useEffect(() => {
			setSignUpServiceType(signUpServiceTypeOverride);
		}, [signUpServiceTypeOverride]);
		return null;
	};

	export const Initialize = () => {
		const { address, savingsData } = useAppealContext();

		const isAbTestTarget = () => {
			return (
				!!savingsData?.inService &&
				["CA", "FL", "NY"].includes(address?.state ?? "")
			);
		};

		if (!isAbTestTarget()) return null;

		return (
			<AbTest testName="ca_fl_ny_signup_savings_variation">
				{({ variant }) => (
					<>
						{variant === "appeal_universal_savings_ui" ? (
							<OverrideSignUpServiceType signUpServiceTypeOverride="appeal" />
						) : (
							<OverrideSignUpServiceType signUpServiceTypeOverride="default" />
						)}
					</>
				)}
			</AbTest>
		);
	};
}
