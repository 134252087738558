import {
	createContext,
	Dispatch,
	FC,
	SetStateAction,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from "react";
import client from "utils/client";
import { createSatelliteUrlCss } from "utils/createSatelliteUrl";
import { ExpensesData, FileData, PropertyDetails } from "utils/types";
import Router, { useRouter } from "next/router";
import { useAuthContext } from "context/AuthContext";
import { shouldSeeProfile } from "utils/account";
import { getMostRecentAppeal } from "utils/getMostRecentAppeal";
import { sendErrorToWebhook } from "utils/sendWebhook";
import { usePropertySurveyFlowMachine } from "./Survey/usePropertySurveryFlowMachine";
import { PropertySurveyQuestionFlowContext } from "./Survey/propertySurveyQuestionFlow.machine";
import { formatDollarStr } from "utils/formatDollar";
import axios from "axios";
import { usePropertyEquityContext } from "./PropertyEquityContext";

const CURRENT_YEAR = new Date().getFullYear();

export type PropertyModalNames =
	| "yard-sign"
	| "profile"
	| "details"
	| "edit-details"
	| "status-details"
	| "insurance-survey"
	| "insurance-waitlist"
	| "needs-attention"
	| "upload-documents"
	| "exemptions"
	| "upload-license"
	| "edit-equity-details"
	| "edit-equity-details-mortgage-paid-off"
	| "preparing-tax-data"
	| "expenses"
	| "referral"
	| "bill-reduction"
	| "more-ways-to-save"
	| "roi-calculation-explanation"
	| "insurance-contact"
	| "refinance-monitoring-survey-flow";

interface IPropertyContext {
	details: PropertyDetails | undefined;
	setDetails: Dispatch<SetStateAction<PropertyDetails | undefined>>;
	satelliteUrlCss: string | undefined;
	activeQuestionId: string;
	setModalToShow: Dispatch<SetStateAction<PropertyModalNames | undefined>>;
	modalToShow: PropertyModalNames | undefined;
	loading: boolean;
	setLoading: Dispatch<SetStateAction<boolean>>;
	profileFlowProgress: number | undefined;
	profileFlowDone: boolean;
	next: (skip?: boolean) => void;
	prev: () => void;
	initSurvey: () => void;
	restartSurvey: () => void;
	resetProfile: () => void;
	Question: FC | undefined;
	allUploadedPropertySurveyFiles: string[];
	needsAttentionModalAppealId: number | undefined;
	setNeedsAttentionModalAppealId: Dispatch<SetStateAction<number | undefined>>;
	expensesData: ExpensesData | null;
	setExpensesData: Dispatch<SetStateAction<ExpensesData | null>>;
	loadingExpensesData: boolean;
	setLoadingExpensesData: Dispatch<SetStateAction<boolean>>;
	fetchPropertyDetails: (
		id: string,
		opts: { autoOpenNARModal: boolean }
	) => Promise<void>;
	showPropSurveyBackButton: boolean;
	propertySurveyContext: PropertySurveyQuestionFlowContext;
	send: ReturnType<typeof usePropertySurveyFlowMachine>["send"] | (() => void);
	isInOnboardingFlow: boolean;
	setIsInOnboardingFlow: Dispatch<SetStateAction<boolean>>;
}

export const PropertySurveyPhotoUploadPrompt = `Photos should be taken in ${
	CURRENT_YEAR - 1
} or ${CURRENT_YEAR}.`;

const PropertyContext = createContext<IPropertyContext>({
	details: undefined,
	activeQuestionId: "",
	setDetails: () => {},
	satelliteUrlCss: undefined,
	setModalToShow: () => {},
	modalToShow: undefined,
	setLoading: () => {},
	loading: true,
	resetProfile: () => {},
	profileFlowProgress: undefined,
	profileFlowDone: false,
	next: () => {},
	prev: () => {},
	initSurvey: () => {},
	restartSurvey: () => {},
	Question: undefined,
	allUploadedPropertySurveyFiles: [],
	needsAttentionModalAppealId: undefined,
	setNeedsAttentionModalAppealId: () => {},
	expensesData: null,
	setExpensesData: () => {},
	loadingExpensesData: true,
	setLoadingExpensesData: () => {},
	fetchPropertyDetails: async () => {},
	showPropSurveyBackButton: false,
	propertySurveyContext: {
		direction: "forwards",
		progress: undefined,
		files: [],
		state: "",
		enterprise: false,
		q_profile_seen_intro: 0,
		q_notice_received: null,
		q_primary_residence: null,
		q_current_homestead_status: null,
		is_takeover: null,
		q_hearing_notice: null,
		q_hearing_notice_date: null,
		question_deferred: null,
		question_purchased: null,
		question_income: null,
		//question_heardabout: null,
		q_yardsign: null,
		last_sale_date_prop: null,
		last_sale_price_prop: null,
		question_armslength: null,
		question_armslength_desc: null,
		q_negative_impact: null,
		q_negative_impact_list: null,
		q_negative_impact_date: null,
		q_negative_impact_cost: null,
		q_negative_impact_other: null,
		q_flooded: null,
		q_flooded_cause: null,
		q_flooded_cost: null,
		q_flooded_desc: null,
		q_flooded_date: null,
		q_structural_issues: null,
		q_structural_issues_list: null,
		q_structural_issues_desc: null,
		q_structural_issues_cost: null,
		q_structural_issues_date: null,
		q_roof_damage: null,
		q_roof_damage_desc: null,
		q_roof_damage_date: null,
		q_roof_damage_cost: null,
		q_major_damage: null,
		q_major_damage_list: null,
		q_major_damage_desc: null,
		q_major_damage_cost: null,
		q_major_damage_date: null,
		q_catchall: null,
		q_catchall_desc: null,
		q_catchall_cost: null,
		q_catchall_date: null,
		q_wildcard_type: null,
		q_wildcard_answer: null,
		account_tier: null,
		current_account_tier: null,
		customer_completed_survey: 0,
		county: "",
		auth_company_type: "",
		//has_answered_heardabout: 0,
		show_internet_reduction_question: true,
		show_insurance_monitoring_question: true,
		show_mortgage_and_equity_question: true,
		in_season: true,
	},
	send: () => {},
	isInOnboardingFlow: false,
	setIsInOnboardingFlow: () => {},
});

export const usePropertyContext = () => useContext(PropertyContext);

export const PropertyContextProvider: FC<{
	id: string;
}> = ({ children, id }) => {
	const [details, setDetails] = useState<PropertyDetails>();
	const [loading, setLoading] = useState(false);
	const [needsAttentionModalAppealId, setNeedsAttentionModalAppealId] =
		useState<number>();
	const [modalToShow, setModalToShow] = useState<
		PropertyModalNames | undefined
	>();
	const [expensesData, setExpensesData] = useState<ExpensesData | null>(null);
	const [loadingExpensesData, setLoadingExpensesData] = useState(true);
	const [isInOnboardingFlow, setIsInOnboardingFlow] = useState(false);

	const { isAuthenticated, properties, setProperties, userData } =
		useAuthContext();

	const satelliteUrlCss = useMemo(
		() => (details ? createSatelliteUrlCss(details) : undefined),
		[details]
	);

	const router = useRouter();

	useEffect(() => {
		if (router.query.onboarding) {
			setIsInOnboardingFlow(true);
		} else {
			setIsInOnboardingFlow(false);
		}
	}, [router.query.onboarding]);

	const fetchPropertyDetails = useCallback(
		async (id: string, opts: { autoOpenNARModal: boolean }) => {
			try {
				setLoading(true);
				const res = await client.getProperty({ id });

				setDetails(res.data);

				setProperties(prevProperties => {
					const propertyIndex = prevProperties.findIndex(
						property => property.id === res.data.id
					);
					if (propertyIndex > -1) {
						const newProperties = [...prevProperties];
						newProperties[propertyIndex] = res.data;
						return newProperties;
					}

					return prevProperties;
				});

				//res.data.tax_year is the most recent tax year
				const appealRequiringAction = res.data.appeals.find(
					appeal =>
						appeal.tax_year === res.data.tax_year &&
						appeal.needs_attention_reason.length &&
						appeal.needs_attention_reason.some(
							reason => !reason.documentsUnderReview
						)
				);

				// Only auto-open the NAR modal if the user was directed to the property page ...
				// ... not if they were directed to a nested page (e.g. /account/properties/123/exemptions)
				if (
					appealRequiringAction &&
					opts.autoOpenNARModal &&
					window.location.pathname === `/account/properties/${id}` &&
					window.location.search !== `?premium-pricing=true`
				) {
					Router.push(
						`/account/properties/${id}/needs-attention?appeal_id=${appealRequiringAction.appeal_id}`
					);
				}
			} catch (e) {
				console.error(e);
				Router.push("/account/properties/error");
			} finally {
				setLoading(false);
			}
		},
		[userData?.id]
	); //do not include properties in dependency array

	const {
		next,
		prev,
		send,
		init: initSurvey,
		restart: restartSurvey,
		activeQuestionId,
		state: { context: propertySurveyContext },
		Question,
		isComplete: profileFlowDone,
		showBackButton: showPropSurveyBackButton,
	} = usePropertySurveyFlowMachine(details, modalToShow);

	const { shouldShowStartTrackingIntro } = usePropertyEquityContext();

	useEffect(() => {
		// re-initialize property survey if router.query.onboarding changes
		initSurvey();
	}, [router.query.onboarding]); // eslint-disable-line react-hooks/exhaustive-deps

	// response values update react state
	useEffect(() => {
		const appeal = getMostRecentAppeal(details);

		if (!appeal || !userData || !details) return;

		send({
			type: "update_context",
			context: {
				...appeal,
				//has_answered_heardabout: userData?.has_answered_heardabout,
				county: details?.county,
				state: details?.state,
				auth_company_type: details?.auth_company_type,
				enterprise: !!details?.income_producing,
				show_internet_reduction_question: !details?.bill_reduction?.find(
					br => br.category === "Internet"
				),
				show_insurance_monitoring_question: !details?.insurance?.insurance_id,
				show_mortgage_and_equity_question: shouldShowStartTrackingIntro,
				in_season: shouldSeeProfile(details),
				account_tier: userData?.account_tier,
				current_account_tier: userData?.account_tier,
			},
		});
		send({ type: "init" });
	}, [details, userData]);

	const profileFlowProgress = useMemo(
		() => propertySurveyContext.progress,
		[propertySurveyContext]
	);

	const resetProfile = () => {
		if (details) {
			fetchPropertyDetails(details.id.toString(), {
				autoOpenNARModal: false,
			})
				.then(() => {
					initSurvey();
				})
				.catch(err => {
					console.error(err);
					sendErrorToWebhook("Error caught in resetProfile: " + err);
				});
		}
	};

	const allUploadedPropertySurveyFiles = useMemo(() => {
		return propertySurveyContext.files?.map(({ file_name }) => file_name);
	}, [propertySurveyContext.files]);

	useEffect(() => {
		if (isAuthenticated && userData && id)
			fetchPropertyDetails(id, { autoOpenNARModal: true });
	}, [id, isAuthenticated, fetchPropertyDetails, userData]);

	useEffect(() => {
		if (!details) return;

		const url = new URL(window.location.href);

		if (url.pathname.endsWith("/survey")) {
			if (shouldSeeProfile(details)) {
				setModalToShow("profile");
			} else {
				Router.push({
					pathname: `/account/properties/${details.id}`,
					query: { ...(router.query.onboarding ? { onboarding: true } : {}) },
				});
			}
			return;
		}
		if (url.pathname.endsWith("/needs-attention")) {
			const needsAttentionAppealId = parseInt(
				url.searchParams.get("appeal_id") || "0"
			);
			if (url.searchParams.get("appeal_id") && needsAttentionAppealId) {
				setNeedsAttentionModalAppealId(needsAttentionAppealId);
				setModalToShow("needs-attention");
			}
			return;
		}

		if (url.pathname.endsWith("/exemptions")) {
			//add logic to show exemptions depending on flag
			setModalToShow("exemptions");
			return;
		}

		if (url.pathname.endsWith("/yard-sign")) {
			setModalToShow("yard-sign");
			return;
		}

		if (
			url.pathname.endsWith("/expenses") &&
			url.searchParams.get("edit") === "true"
		) {
			setModalToShow("expenses");
			return;
		}

		if (url.pathname.endsWith("/insurance")) {
			setModalToShow("insurance-survey");
			return;
		}

		if (url.pathname.endsWith("/files")) {
			setModalToShow("upload-documents");
			return;
		}

		if (url.searchParams.get("exemptionsnar") === "dl") {
			setModalToShow("upload-license");
			return;
		}

		if (url.searchParams.get("exemptionsnar") === "ny-tax") {
			setModalToShow("exemptions");
			return;
		}

		if (url.searchParams.get("exemptionsnar") === "ny-invalid-credentials") {
			setModalToShow("exemptions");
			return;
		}

		if (url.searchParams.get("bill-reduction")) {
			setModalToShow("bill-reduction");
			return;
		}

		// When user navigates back, unset modalToShow
		setModalToShow(undefined);
	}, [details, router.asPath]); // Listen for changes to router.asPath

	useEffect(() => {
		setDetails(undefined);
		setModalToShow(undefined);
		setNeedsAttentionModalAppealId(undefined);
		initSurvey();
	}, [id]); // Do not include resetQuestionState in dependency array

	return (
		<PropertyContext.Provider
			value={{
				details,
				setDetails,
				activeQuestionId,
				satelliteUrlCss,
				loading,
				setLoading,
				profileFlowDone,
				profileFlowProgress,
				setModalToShow,
				modalToShow,
				prev,
				next,
				initSurvey,
				restartSurvey,
				resetProfile,
				Question,
				allUploadedPropertySurveyFiles,
				needsAttentionModalAppealId,
				setNeedsAttentionModalAppealId,
				expensesData,
				setExpensesData,
				loadingExpensesData,
				setLoadingExpensesData,
				fetchPropertyDetails,
				showPropSurveyBackButton,
				propertySurveyContext,
				send,
				isInOnboardingFlow,
				setIsInOnboardingFlow,
			}}>
			{children}
		</PropertyContext.Provider>
	);
};
