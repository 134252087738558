import Concierge from "public/account/survey/concierge/concierge.svg";
import { PARTICLE_CONFIG } from "../../PremiumPricingModal";
import Particles from "react-particles";
import { loadSlim } from "tsparticles-slim";
import { loadConfettiPreset } from "tsparticles-preset-confetti";
import { useCallback } from "react";

const ConciergeConfirmationContent = () => {
	const particlesInit = useCallback(async engine => {
		await loadConfettiPreset(engine);
		await loadSlim(engine);
	}, []);

	return (
		<div className="concierge-confirmation-content">
			<div className="img-wrapper">
				<Concierge />
			</div>
			<p className="h4">Welcome to Concierge</p>
			<p className="small">
				A Concierge teammate will reach out with next steps, in the meantime
				continue activating all of your Ways to Save!
			</p>
			<Particles
				id="tsparticles"
				options={PARTICLE_CONFIG}
				init={particlesInit}
			/>
		</div>
	);
};

export default ConciergeConfirmationContent;
