import ButtonV2 from "elements/ButtonV2";
import CircleCheck from "public/account/survey/concierge/circle-check.svg";
import CircleExclaim from "public/account/survey/concierge/circle-exclaim.svg";
import ShieldCheck from "public/account/survey/concierge/shield-check.svg";

const ConciergeProOptionCard = ({
	billingCycle,
	contingencyPercentage,
	onOptionSelect,
}: {
	billingCycle: string;
	contingencyPercentage: number;
	onOptionSelect: (option: string) => void;
}) => {
	return (
		<div className="concierge-options-card concierge-pro-option">
			<div className="card-header">
				<p className="card-heading">Concierge Pro</p>
			</div>
			<div className="pricing">
				<p className="strikeout-price">
					{billingCycle == "annually" ? "$50.00/month" : ""}
				</p>
				<p className="price h5">
					{billingCycle == "annually" ? "$42.50" : "$50"}
					<span className="xs">/month</span>
				</p>
				<p className="billing-cycle">
					{billingCycle == "annually"
						? "$510 billed annually"
						: "$600 per year"}
				</p>
				<div className="percent-contingency">
					<p className="tiny">{contingencyPercentage}% contingency</p>
					<CircleExclaim />
				</div>
			</div>
			<div className="cta">
				<ButtonV2
					variant="primary-outline"
					size="extra-small"
					className="w-100"
					onClick={() => onOptionSelect("concierge-pro")}>
					Upgrade To Concierge Pro
				</ButtonV2>
			</div>
			<div className="info">
				<p className="description xs">
					For households who want a white glove home expense management. An
					Ownwell expert researches all your expenses and creates a personalize
					plan to reduce them.
				</p>
			</div>
			<div className="features">
				<p className="feature-heading">What's Included?</p>
				<div className="feature-list">
					<p className="pre-requisite">Everything in Concierge Plan +</p>
					<div className="list-item">
						<p className="list-item-label">
							<span className="icon">
								<CircleCheck />
							</span>
							<span>Auto-pilot cost cutting at every chance we can!</span>
						</p>
					</div>
					<div className="list-item">
						<p className="list-item-label">
							<span className="icon">
								<CircleCheck />
							</span>
							<span>Personalized maintenance recommendations</span>
						</p>
					</div>
					<div className="list-item">
						<p className="list-item-label">
							<span className="icon">
								<CircleCheck />
							</span>
							<span>Dedicated home manager for your property</span>
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ConciergeProOptionCard;
