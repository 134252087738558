import { useRouter } from "next/router";
import GlobeIcon from "public/globe.svg";
import { FC, useEffect, useState } from "react";

export const LanguagePickerDropdown: FC<
	React.HTMLAttributes<HTMLDivElement>
> = props => {
	const [dropdownOpen, setDropdownOpen] = useState(false);

	const router = useRouter();

	const currentLocaleLabel = router.locale?.split("-")[0].toUpperCase();

	const localOptions = router.locales?.map(locale => {
		let label = locale.split("-")[0].toUpperCase();
		let icon = "";

		switch (label) {
			case "EN":
				icon = "🇺🇸 ";
				label = "English";
				break;
			case "ES":
				icon = "🇲🇽";
				label = "Español";
				break;
		}

		return {
			label,
			icon,
			value: locale,
		};
	});

	const switchLanguage = (locale: string) => {
		//@ts-ignore
		Localize.setLanguage(locale);
		setDropdownOpen(false);
	};

	useEffect(() => {
		const closeDropdown = () => {
			setDropdownOpen(false);
		};

		if (dropdownOpen) {
			window.addEventListener("click", closeDropdown);
		} else {
			window.removeEventListener("click", closeDropdown);
		}

		return () => {
			window.removeEventListener("click", closeDropdown);
		};
	}, [dropdownOpen]);

	return (
		<div
			{...props}
			className={
				"language-picker-dropdown-container " + (props.className ?? "")
			}>
			<div
				className="language-picker-dropdown"
				onClick={e => {
					e.stopPropagation();
					setDropdownOpen(prevOpen => !prevOpen);
				}}>
				<GlobeIcon />
				<p className="sm language-picker-dropdown-locale">
					{currentLocaleLabel}
				</p>
			</div>
			{dropdownOpen && (
				<div className="language-picker-dropdown-list">
					{localOptions?.map(option => {
						return (
							<div
								key={option.value}
								className="language-picker-dropdown-list-item"
								onClick={() => switchLanguage(option.value)}>
								<p className="sm">
									<span className="language-picker-dropdown-list-item-icon">
										{option.icon}
									</span>{" "}
									{option.label}
								</p>
							</div>
						);
					})}
				</div>
			)}
		</div>
	);
};
